/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({ description, keywords, title, image, pathname, children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
          }
        }
      }
    `
  );


  const baseUrl = site.siteMetadata.siteUrl + '/' + pathname;

  const defaultKeyWords = 'viennaeventtickets, vienna, ticket, opera, vienna event ticket, online booking';

  const seo = {
    title: title || site.siteMetadata.title,
    description: description || site.siteMetadata.description,
    image: image || site.siteMetadata.image,
    url: baseUrl,
    keywords: keywords !== undefined ? keywords : defaultKeyWords
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="theme-color" content="#e6594d" />
      <meta name="type" content="website" />
      <meta name="og:type" content="website" />
      <meta name="title" content={seo.title} />
      <meta name="og:title" content={seo.title} />
      <meta name="description" content={seo.description} />
      <meta name="og:description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="og:image" content={seo.image} />
      <meta name="keywords" content={seo.keywords} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="fb:app_id" content="5816822891712798" />
      {children}
    </>
  );
}

Seo.defaultProps = {
  title: ``,
  keywords: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default Seo;
